const path = require('path');

const routes = {
  home: {
    path: '/',
    component: path.resolve(__dirname, 'screens/Home/index.tsx'),
  },
  aboutTrialLesson: {
    path: '/about-trial-lesson/',
    component: path.resolve(__dirname, 'screens/AboutTrialLesson/index.tsx'),
  },
  afterTrialLesson: {
    path: '/after-trial-lesson/',
    component: path.resolve(__dirname, 'screens/AfterTrialLesson/index.tsx'),
  },
  payments: {
    path: '/chess-pay/',
    component: path.resolve(__dirname, 'screens/ChessPay/index.tsx'),
  },
  instruction: {
    path: '/instruction/',
    component: path.resolve(__dirname, 'screens/Instruction/index.js'),
  },
  notFound: {
    path: '404.html',
    component: path.resolve(__dirname, 'screens/404/index.tsx'),
  },
};

/**
 * @type {{ home, payments, instruction, notFound }}
 */
const paths = Object.keys(routes).reduce((acc, route) => {
  acc[route] = routes[route].path;
  return acc;
}, {});

module.exports = {
  routes,
  paths,
};
