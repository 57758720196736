import React from 'react';
import { CurrencyContext } from 'containers/Currency/CurrencyContext';
import { LocationContext } from 'containers/Location/LocationContext';
import { usePrice } from 'utils/hooks/usePrice';
import { ICurrencyRate } from 'types/currency';
import { fetchCurrencyRate } from './api';

interface IProps {
  children: React.ReactNode;
}

export const CurrencyProvider = ({ children }: IProps) => {
  const [currencyRate, setCurrencyRate] = React.useState<ICurrencyRate | null>(
    null,
  );
  const location = React.useContext(LocationContext);
  const price = usePrice();

  React.useEffect(() => {
    if (location.isReal && price) {
      if (price.currencyCode !== location.currencyCode) {
        const fetchAsync = async () => {
          const rate = await fetchCurrencyRate(
            price.currencyCode,
            location.currencyCode,
          );
          setCurrencyRate(rate);
        };
        fetchAsync();
      }
    }
  }, [location]);

  return (
    <CurrencyContext.Provider value={currencyRate}>
      {children}
    </CurrencyContext.Provider>
  );
};
