// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-screens-404-index-tsx": () => import("./../../../src/screens/404/index.tsx" /* webpackChunkName: "component---src-screens-404-index-tsx" */),
  "component---src-screens-about-trial-lesson-index-tsx": () => import("./../../../src/screens/AboutTrialLesson/index.tsx" /* webpackChunkName: "component---src-screens-about-trial-lesson-index-tsx" */),
  "component---src-screens-after-trial-lesson-index-tsx": () => import("./../../../src/screens/AfterTrialLesson/index.tsx" /* webpackChunkName: "component---src-screens-after-trial-lesson-index-tsx" */),
  "component---src-screens-chess-pay-index-tsx": () => import("./../../../src/screens/ChessPay/index.tsx" /* webpackChunkName: "component---src-screens-chess-pay-index-tsx" */),
  "component---src-screens-home-index-tsx": () => import("./../../../src/screens/Home/index.tsx" /* webpackChunkName: "component---src-screens-home-index-tsx" */),
  "component---src-screens-instruction-index-js": () => import("./../../../src/screens/Instruction/index.js" /* webpackChunkName: "component---src-screens-instruction-index-js" */)
}

