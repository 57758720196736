import React from 'react';
import phoneImg from 'assets/images/phone.svg';
import hamburgerImg from './images/hamburger.png';
import { Link } from 'gatsby';
import { usePhone } from 'utils/hooks/usePhone';
import { SidebarContext } from '../Sidebar/SidebarContext';
import { DesktopHeader } from './DesktopHeader';
import { routes } from 'routes';

export const Header = () => {
  const { setExpanded, setFixed } = React.useContext(SidebarContext);
  const phone = usePhone();

  const onExpand = React.useCallback(() => {
    setExpanded(true);
    setFixed(true);
  }, []);

  return (
    <header>
      <div className="mobile-header">
        <div className="mobile-header-content container">
          <button onClick={onExpand} className="hamburger-btn">
            <img src={hamburgerImg} alt="Меню" />
          </button>
          <Link to={routes.home.path} className="logo-link">
            <b>Chess UP!</b> School
          </Link>
          <a className="phone-link" href={phone.href}>
            <img src={phoneImg} alt="Телефон" />
          </a>
        </div>
      </div>
      <DesktopHeader />
    </header>
  );
};
