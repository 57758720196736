import React from 'react';
import logoImg from './images/logo.svg';
import { Link } from 'gatsby';
import { routes } from 'routes';
import { NavLinks } from './NavLinks';
import { Contacts } from './Contacts';
import { YoutubeLinks } from './YoutubeLinks';

export const Footer = () => (
  <footer>
    <div className="container footer-content">
      <Link to={routes.home.path} className="footer-logo">
        <img src={logoImg} alt="Логотип Chess UP! School" />
      </Link>
      <div className="nav-links-wrapper">
        <NavLinks />
        <Contacts />
      </div>
      <YoutubeLinks />
    </div>
  </footer>
);
