import React from 'react';
import { IntlProvider } from 'react-intl';
import { ToastContainer } from 'react-toastify';
import { translationMessages } from 'config/app-18n';
import { LocationProvider } from 'containers/Location/LocationProvider';
import { CurrencyProvider } from 'containers/Currency/CurrencyProvider';
import { Helmet } from 'react-helmet';
import fontMullerBlack from 'assets/fonts/Muller/Muller-Black/MullerBlack.woff2';
import fontMullerRegular from 'assets/fonts/Muller/Muller-Regular/MullerRegular.woff2';
import fontMullerMedium from 'assets/fonts/Muller/Muller-Medium/MullerMedium.woff2';
import 'containers/System';
import 'styles/global.scss';

interface IProps {
  element: Element;
}

const Root = ({ element }: IProps) => {
  return (
    <React.Fragment>
      <Helmet>
        <link rel="preconnect" href="https://www.google-analytics.com" />
        <link rel="preconnect" href="https://mc.yandex.ru" />
        <link rel="preconnect" href="https://pro.ip-api.com" />
        <link
          rel="preload"
          as="font"
          href={fontMullerBlack}
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href={fontMullerRegular}
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href={fontMullerMedium}
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Helmet>
      <IntlProvider key="ru" locale="ru" messages={translationMessages.ru}>
        <React.Fragment>
          <ToastContainer />
          <LocationProvider>
            <CurrencyProvider>
              <div className="page-wrapper">{element}</div>
            </CurrencyProvider>
          </LocationProvider>
        </React.Fragment>
      </IntlProvider>
    </React.Fragment>
  );
};

export { Root };
