import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import { useLocation } from '@reach/router';

interface IProps {
  icon: any;
  title: string;
  path: string;
  className: string;
  nestedLinks?: {
    title: string;
    path?: string;
    pathType: 'local' | 'external' | 'none';
  }[];
  onClick: () => void;
}

export const NavLink = ({
  icon: Icon,
  title,
  path,
  onClick,
  className,
  nestedLinks,
}: IProps) => {
  const { pathname } = useLocation();
  return (
    <li className={cx('nav-item nav-link', className)}>
      <a
        className={cx({ active: pathname === path })}
        href={path}
        onClick={onClick}
      >
        <Icon className="link-icon" />
        <div className="link-title">{title}</div>
      </a>
      {nestedLinks && (
        <ul className="nested-links">
          {nestedLinks.map((nestedLink, index) => (
            <li key={index}>
              {nestedLink.pathType === 'local' && nestedLink.path && (
                <Link
                  onClick={onClick}
                  className={cx('nested-link', {
                    active: nestedLink.path === pathname,
                  })}
                  to={nestedLink.path}
                >
                  {nestedLink.title}
                </Link>
              )}
              {nestedLink.pathType === 'external' && (
                <a
                  className="nested-link"
                  onClick={onClick}
                  href={nestedLink.path}
                >
                  {nestedLink.title}
                </a>
              )}
              {nestedLink.pathType === 'none' && (
                <div className="nested-link">{nestedLink.title}</div>
              )}
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};
