import React from 'react';
import { CONTRACT_OFFER_LINK } from 'constants/contacts';
import { getUpSchoolLink } from 'utils/getUpSchoolLink';
import { UPSCHOOL_ROUTES } from 'constants/routes';

export const NavLinks = () => (
  <ul className="footer-nav-links">
    <li>
      <a className="nav-link" href={getUpSchoolLink(UPSCHOOL_ROUTES.reviews)}>
        Отзывы
      </a>
    </li>
    <li>
      <a
        className="nav-link"
        href={getUpSchoolLink(UPSCHOOL_ROUTES.promotions)}
      >
        Акции
      </a>
    </li>
    <li>
      <a className="nav-link" href={getUpSchoolLink(UPSCHOOL_ROUTES.contacts)}>
        Контакты
      </a>
    </li>
    <li>
      <a className="nav-link" href={getUpSchoolLink(UPSCHOOL_ROUTES.payments)}>
        Способы оплаты
      </a>
    </li>
    <li>
      <a
        href={CONTRACT_OFFER_LINK}
        target="_blank"
        className="nav-link"
        rel="noreferrer"
      >
        Договор оферты
      </a>
    </li>
  </ul>
);
