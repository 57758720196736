import React from 'react';
import logoImg from './images/logo.png';
import { paths } from 'routes';
import { Link } from 'gatsby';

export const Logo = () => {
  return (
    <Link to={paths.home} className="logo">
      <img className="logo-img" src={logoImg} alt="Логотип Chess UP! School" />
      <div className="d-flex flex-column justify-content-center mt-1">
        <div className="title">Chess UP! School</div>
        <div className="subtitle">Онлайн-школа шахмат</div>
      </div>
    </Link>
  );
};
