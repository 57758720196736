import React from 'react';
import logo from 'assets/images/logo.svg';
import { getUpSchoolLink } from 'utils/getUpSchoolLink';
import { UPSCHOOL_ROUTES } from 'constants/routes';

export const Logo = () => (
  <a href={getUpSchoolLink(UPSCHOOL_ROUTES.home)}>
    <img className="nav-logo" src={logo} alt="Лого UpSchool" />
  </a>
);
