export enum CourseIds {
  PROGRAMMING = 'programming',
  CHESS = 'chess',
  MATH = 'math',
}

export enum LessonType {
  GROUP = 'group',
  INDIVIDUAL = 'individual',
}
