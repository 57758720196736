/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { Root } from './src/containers/Root';
import { Layout } from './src/containers/Layout';

const addScript = (url, reject) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  script.onerror = reject;
  document.head.appendChild(script);
};

const wrapRootElement = Root;
const wrapPageElement = Layout;
const onClientEntry = () => {
  return new Promise((resolve, reject) => {
    window.__polyfillio__ = () => {
      resolve();
    };
    if (!('Intl' in window)) {
      const locale = navigator.language;
      addScript(
        `https://polyfill.io/v3/polyfill.min.js?features=Intl.~locale.${locale}&callback=__polyfillio__`,
        reject,
      );
    } else {
      resolve();
    }
  });
};

let nextRoute = ``;

const onPreRouteUpdate = ({ location }) => {
  nextRoute = location.pathname;
};

window.addEventListener('unhandledrejection', (event) => {
  if (/loading chunk \d* failed./i.test(event.reason)) {
    if (nextRoute) {
      window.location.pathname = nextRoute;
    }
  }
});

export { wrapRootElement, wrapPageElement, onClientEntry, onPreRouteUpdate };
