import { IPhone } from 'types/phone';

export const PHONE_NUMBERS: { [key: string]: IPhone } = {
  by: {
    formattedNumber: '+375 (33) 657-55-33',
    href: 'tel:+375336575533',
  },
  ru: {
    formattedNumber: '+7 (916) 131-93-30',
    href: 'tel:+79161319330',
  },
};

export const DEFAULT_PHONE_NUMBER = PHONE_NUMBERS.ru;

export const SOCIAL_LINKS = {
  vk: 'https://vk.com/public183916574',
  facebook: 'https://www.facebook.com/groups/468679333959066/',
  twitter: 'https://twitter.com/chessup_school',
  instagram: 'https://www.instagram.com/chess.up.school/',
  chessYoutube: 'https://www.youtube.com/channel/UCsbQ1PK0JHWHa0wEWleHArg',
  programmingYoutube:
    'https://www.youtube.com/channel/UC0lW_B_uyIJw-dE62aja3_Q',
};

export const MESSENGER_LINKS = {
  viber: 'viber://chat/?number=%2B375336575533',
  whatsApp: 'https://wa.me/375336575533',
  telegram: 'https://t.me/upschoolonline',
  messenger: 'https://m.me/102552528279540',
};

export const CONTRACT_OFFER_LINK =
  'https://s3.up-school.online/contract-offer.docx';
