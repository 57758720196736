import React from 'react';
import chessBoardImg from './images/chess-board.png';

import { MESSENGER_LINKS, SOCIAL_LINKS } from 'constants/contacts';
import youtubeImg from 'assets/images/socials/youtube.svg';
import instagramImg from 'assets/images/socials/instagram.svg';
import viberImg from 'assets/images/socials/viber.svg';
import telegramImg from 'assets/images/socials/telegram.svg';

import { Logo } from './Logo';
import { Phone } from './Phone';

export const DesktopHeader = () => (
  <div className="desktop-header container">
    <div className="desktop-header-content">
      <Logo />
      <div className="chess-board">
        <div className="chess-board-img">
          <img
            className="chess-board-img"
            src={chessBoardImg}
            alt="шахматная доска"
          />
        </div>
      </div>
      <div className="ml-auto d-flex">
        <div className="contacts-wrapper">
          <div className="messengers">
            <a href={MESSENGER_LINKS.viber} className="messenger-link">
              <img src={viberImg} alt="viber" />
            </a>
            <a
              href={MESSENGER_LINKS.telegram}
              className="messenger-link"
              target="_blank"
              rel="noreferrer"
            >
              <img src={telegramImg} alt="telegram" />
            </a>
          </div>
          <Phone />
          <div className="socials">
            <a
              href={SOCIAL_LINKS.chessYoutube}
              target="_blank"
              rel="noreferrer"
              className="social-link"
            >
              <img src={youtubeImg} alt="youtube" />
            </a>
            <a
              href={SOCIAL_LINKS.instagram}
              target="_blank"
              rel="noreferrer"
              className="social-link"
            >
              <img src={instagramImg} alt="instagram" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);
