import React from 'react';

import callAnswer from 'assets/images/call-answer.png';
import { DynamicContentOverlay } from 'components/DynamicContentOverlay';
import { usePhone } from 'utils/hooks/usePhone';

export const Phone = () => {
  const phone = usePhone();
  return (
    <DynamicContentOverlay>
      <a href={`tel:${phone.href}`} className="phone-link">
        <img src={callAnswer} alt="телефонная трубка" />
        {phone.formattedNumber}
      </a>
    </DynamicContentOverlay>
  );
};
