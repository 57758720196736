import React from 'react';
import { usePhone } from 'utils/hooks/usePhone';
import { DynamicContentOverlay } from 'components/DynamicContentOverlay';
import { MESSENGER_LINKS } from 'constants/contacts';
import phoneImg from 'assets/images/phone.svg';
import viberImg from 'assets/images/socials/viber.svg';
import telegramImg from 'assets/images/socials/telegram.svg';

export const NavContacts = () => {
  const phone = usePhone();
  return (
    <div className="nav-item nav-contacts">
      <a className="phone-link" href={phone.href}>
        <img src={phoneImg} alt="Телефон" />
        <DynamicContentOverlay>
          <span className="phone-link-text">{phone.formattedNumber}</span>
        </DynamicContentOverlay>
      </a>
      <div className="messenger-links-wrapper">
        <div className="messenger-links">
          <a className="messenger-link" href={MESSENGER_LINKS.viber}>
            <img src={viberImg} alt="Viber" />
          </a>
          <a
            className="messenger-link"
            href={MESSENGER_LINKS.telegram}
            target="_blank"
            rel="noreferrer"
          >
            <img src={telegramImg} alt="Whatsapp" />
          </a>
        </div>
      </div>
    </div>
  );
};
