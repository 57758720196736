export const UPSCHOOL_ROUTES = {
  home: '/',
  programming: '/programming/',
  promotions: '/promotions/',
  trialLesson: '/trial-lesson/',
  reviews: '/reviews/',
  contacts: '/contacts/',
  payments: '/payments/',
  programmingPay: '/programming-pay/',
};

export const UPSCHOOL_DOMAIN = 'https://up-school.online';
